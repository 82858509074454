import shopifySparkGenericDefaults from '../shopify-spark-generic/config.js';

export default {
  includes: ['shopify-spark-generic'],
  ...shopifySparkGenericDefaults,
  SearchRequestDefaults: {
    ...shopifySparkGenericDefaults.SearchRequestDefaults,
  },
  fitmentSearch: {
    ...shopifySparkGenericDefaults.fitmentSearch,
    isAutoRedirectDisabled: true,
  },
  Widgets: [
    ...shopifySparkGenericDefaults.Widgets,
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm-home',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 730,
    },
  ],
};
