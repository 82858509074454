//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-328:_4276,_4456,_8800,_8880,_3144,_762,_40,_7836;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-328')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-328', "_4276,_4456,_8800,_8880,_3144,_762,_40,_7836");
        }
      }catch (ex) {
        console.error(ex);
      }