//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-328:_9890,_7072,_2494,_7080,_3168,_8318,_342,_1174;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-328')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-328', "_9890,_7072,_2494,_7080,_3168,_8318,_342,_1174");
        }
      }catch (ex) {
        console.error(ex);
      }